import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import {Layout} from '@components/layout/layout'
import { H1, H3, Paragph } from "@components/layout/style"
import { B, ListDisc, MainContainer, Question,Response, RightContainer, StyledHero } from "../../components/oferta.style"

const Protetyka:React.FC = ({data}) => {
  return (<Layout data={data} prop={{
    canonicalUrl: 'https://www.mediroot.pl/oferta/protetyka-wagrowiec/',
    title: 'Protetyk Wągrowiec - protezy zębowe - protetyka stomatologiczna | Mediroot',
    description: 'Mediroot ➤ Protetyk Wągrowiec - Protezy zębowe Wągrowiec ✔ Protetyka stomatologiczna ✔ Leczenie protetyczne ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
  }}>
    <StyledHero>
      <StaticImage
        src="../../assets/images/hero.jpg"
        placeholder="blurred"
        alt="protetyk Wągrowiec, protezy zębowe Wągrowiec, protezy zębowe, protetyka stomatologiczna, protetyka zębów, protetyka dentystyczna, protetyk stomatologiczny, leczenie protetyczne"
        layout="fullWidth"/>
    </StyledHero>
    <MainContainer>
      <RightContainer>
        <H1>
          Protezy zębowe Wągrowiec
        </H1>

        <Paragph>
        Protetyka dentystyczna to dział stomatologii poświęcony odtwarzaniu pierwotnych warunków zgryzowych po
utracie zębów naturalnych lub po ich uszkodzeniu, niekwalifikujących się do konwencjonalnego leczenia
zachowawczego. Zęby to części ludzkiego organizmu, które niestety nie ulegają regeneracji. Utrata ich lub
zniszczenie – to strata nieodwracalna biologicznie. Coraz większego znaczenia nabierają możliwości estetycznej
korekty i przebudowy własnych zębów, które są przebarwione i nieprawidłowo ustawione. W takich przypadkach,
dzięki zastosowaniu koron i licówek porcelanowych, możemy uzyskać doskonałe efekty estetyczne i
funkcjonalne. W klinice Mediroot oferujemy protezy akrylowe, natychmiastowe, szkieletowe, korony
stomatologiczne, mosty porcelanowe. Jeśli więc potrzebujesz protetyka w Wągrowcu, to możesz być pewien, że
spośród naszej oferty wybierzemy dla Ciebie najwygodniejsze i najkorzystniejsze rozwiązanie.
        </Paragph>

        <H3>
          Protetyka stomatologiczna – co oferujemy?
        </H3>

        <Paragph >
        Protetyka zębów w naszej klinice obejmuje szereg możliwości od protez natychmiastowych po mosty
porcelanowe. Każdy protetyk stomatologiczny z Mediroot, starannie dobiera odpowiednie rozwiązanie do
indywidualnych potrzeb pacjenta na podstawie wnikliwej diagnostyki.
        </Paragph>

        <H3>
          Protezy akrylowe całkowite i częściowe
        </H3>

        <Paragph >
        Leczenie protetyczne protezami akrylowymi – całkowitymi i częściowymi stosuje się jako jedną z metod
uzupełniania braków zębowych. Jak wygląda przebieg leczenia? Oto uproszczony schemat:
        </Paragph>

        <ListDisc>
          <li>
            <B>I wizyta</B>: po zakwalifikowaniu pacjenta do leczenia protetycznego lekarz pobiera masą wyciskową 2
wyciski – górny i dolny;
          </li>
          <li>
            <B>II wizyta</B>: lekarz stomatolog ustala wysokość zwarcia u pacjenta oraz pobiera , jeżeli jest to konieczne ponowne wyciski na łyżkach indywidualnych wykonanych przez technika stomatologicznego
          </li>
          <li>
            <B>III wizyta</B>: na tej wizycie gotowe są już próbne protezy z zębami zamocowanymi na wosku, po ocenie w ustach i zaakceptowaniu wyglądu protezy przez pacjenta protezy odsyłane są do laboratorium w celu wykonania gotowych
          </li>
          <li>
            <B>IV wizyta</B>: protezy akrylowe są już gotowe do oddania, lekarz stomatolog dokonuje ewentualnych korekt i ocenia prawidłowość zgryzu i ułożenia w jamie ustnej, gotowe dopasowane protezy są oddane pacjentowi
          </li>
        </ListDisc>

        <H3>
          Protezy zębowe natychmiastowe
        </H3>

        <Paragph >
        Protezy natychmiastowe są najszybszym sposobem uzupełnienia braków po usuniętych zębach. Pozwalają na
uniknięcie przykrej dla pacjentów sytuacji, jaką jest brak własnych zębów, zapewniając jednocześnie pełną
funkcjonalność i walory estetyczne. Pacjent po usunięciu zęba wychodzi z gotową protezą uzupełniającą
wszystkie braki. Protezy takie mają wiele zalet, oto niektóre z nich:
        </Paragph>

        <ListDisc>
          <li>
            pacjent może pokazywać się publicznie bez kompleksu. Jest to ważne zwłaszcza po usunięciu zębów
przednich w tzw. strefie estetycznej;
          </li>
          <li>
            dobre gojenie rany poekstrakcyjnej;
          </li>
          <li>
            umożliwiają normalne funkcjonowanie, mówienie, jedzenie bezpośrednio po ekstrakcji zęba;
          </li>
          <li>
            hamują krwawienie z zębodołu i zabezpieczają go przed wnikaniem resztek pokarmowych podczas jedzenia.
          </li>
        </ListDisc>


        <H3>
          Protezy zębowe szkieletowe
        </H3>

        <Paragph >
        Protezy szkieletowe stanowią jedne z najlepszych rozwiązań wśród ruchomych uzupełnień protetycznych.
Wykonane są z metalu, a dzięki mocowaniu na klamrach i nieuciskaniu dziąseł pacjenta, nie wpływają
destrukcyjnie na wyrostek zębodołowy tak jak zwykłe protezy akrylowe. W porównaniu do nich mają wiele zalet,
które sprawiają, że ich użytkowanie jest dużo łatwiejsze i bardziej komfortowe dla pacjenta:
        </Paragph>

        <ListDisc>
          <li>
            dzięki szkieletowi wykonanemu z metalu są dużo cieńsze;
          </li>
          <li>
            prawie nie pokrywają podniebienia – przy górnej protezie;
          </li>
          <li>
            mają lepsze utrzymanie – nie spadają, gdyż umocowane są na dopasowanych klamrach;
          </li>
          <li>
            nie wywołują reakcji alergicznych;
          </li>
          <li>
            nie uciskają dziąseł i podniebienia – nie niszczą wyrostka zębodołowego;
          </li>
          <li>
            mają dużo mniejszy zasięg w jamie ustnej – zajmują niewiele miejsca.
          </li>
        </ListDisc>

        <Paragph>
        Oto uproszczony schemat przebiegu leczenia:
        </Paragph>

        <ListDisc>
          <li>
            <B>I wizyta</B>: po zakwalifikowaniu pacjenta do leczenia protetycznego lekarz stomatolog pobiera wyciski
masą wyciskową;
          </li>
          <li>
            <B>II wizyta</B>: lekarz stomatolog dokonuje przymiarki metalowego szkieletu protezy szkieletowej;
          </li>
          <li>
            <B>III wizyta</B>: lekarz sprawdza protezę szkieletową w ustach pacjenta i po ewentualnych korektach oddaje ją technikowi;
          </li>
          <li>
            <B>IV wizyta</B>: lekarz oddaje gotową protezę pacjentowi.
          </li>
        </ListDisc>


        <H3>
          Korony stomatologiczne
        </H3>

        <Paragph>
        Korony stomatologiczne są to uzupełnienia protetyczne pozwalające odbudować kształt i funkcję zniszczonego
zęba wtedy, gdy nie jest już możliwa standardowa odbudowa zęba za pomocą wypełnienia. W naszym gabinecie
oferujemy korony pełnoceramiczne oraz licowane porcelaną – oba rodzaje koron pozwalają uzyskać doskonały
efekt estetyczny i funkcjonalny.
        </Paragph>

        <Paragph>
        Jak wygląda przebieg leczenia? Oto uproszczony schemat zabiegu:
        </Paragph>

        <ListDisc>
          <li>
            <B>I wizyta</B>: po zakwalifikowaniu zęba do założenia korony protetycznej lekarz przystępuje do szlifowania
  wybranego zęba. Zabieg przeprowadzany jest w znieczuleniu i jest całkowicie bezbolesny. Następnie
  stomatolog pobiera wycisk i odsyła go do laboratorium protetycznego. Wizytę kończy wykonanie korony
  tymczasowej, która zapewnia estetyczny wygląd i funkcjonalność do momentu zacementowania gotowej
  korony protetycznej;
          </li>
          <li>
            <B>II wizyta</B>: po przymiarce i sprawdzeniu gotowej korony protetycznej lekarz cementuje koronę na stałe w
  ustach pacjenta.
          </li>
        </ListDisc>


        <H3>
          Mosty porcelanowe
        </H3>

        <Paragph>
        Porcelanowe mosty stomatologiczne należą do stałych uzupełnień protetycznych. Oznacza to, że są one
zacementowane na stałe na zębach własnych pacjenta lub implantach, dlatego są one bardzo komfortowym i
wygodnym rozwiązaniem protetycznym. Za pomocą mostów stomatologicznych uzupełnia się braki jedno- lub
kilkuzębowe. Most zawieszony na zębach filarowych pacjenta odtwarza brakujące zęby pod względem zarówno
estetycznym, jak i funkcjonalnym. W naszym gabinecie oferujemy mosty protetyczne pełnoceramiczne i licowane
porcelaną.
        </Paragph>

        <Paragph>
          Schemat leczenia:
        </Paragph>

        <ListDisc>
          <li>
            <B>I wizyta</B>: po zaplanowaniu uzupełnienia protetycznego lekarz przystępuje do szlifowania zębów
  wybranych jako filary protetyczne. Zabieg szlifowania przeprowadzany jest w znieczuleniu i jest
  całkowicie bezbolesny. Wizytę kończy pobranie wycisków i odesłanie ich do laboratorium protetycznego
  oraz zabezpieczenie oszlifowanych zębów filarowych;
          </li>
          <li>
            <B>II wizyta</B>: lekarz stomatolog przymierza szkielet mostu w jamie ustnej pacjenta i dokonuje ewentualnych
  korekt, po czym odsyła most do skończenia do laboratorium protetycznego;
          </li>
          <li>
            <B>III wizyta</B>: po przymiarce i sprawdzeniu gotowego mostu lekarz stomatolog cementuje uzupełnienie na
stałe w ustach pacjenta.
          </li>
        </ListDisc>

        <H3>
          Twój protetyk Wągrowiec. Dlaczego Mediroot?
        </H3>

        <Paragph>
          W stomatologii większość rozwiązań ma za zadanie służyć wiele lat, czego przykładem są protezy zębowe.
  Wągrowiec oferuje szereg gabinetów dentystycznych i stomatologów, w tym z dziedziny protetyki, jednak to
  profesjonalizm, nieustanne szkolenia pozwalające aktualizować zdobytą wcześniej wiedzę, duża praktyka i
  najwyższej jakości sprzęt gwarantują dobrze wykonaną protezę. W Mediroot spełniamy wszystkie te wymagania,
  a ponadto zapewniamy przyjazną atmosferę i staramy się zaproponować, możliwie jak najkrótsze terminy
  oczekiwania. Przygotowywane przez nas protezy nie są dziełem przypadku, a wnikliwej diagnostyki. Umów się na
  wizytę, a my przedstawimy Ci wszystkie możliwe rozwiązania, dzięki którym będziesz szeroko się uśmiechać.
        </Paragph>

        <H3>
        FAQ – najczęściej zadawane pytania dotyczące protetyki stomatologicznej
        </H3>

        <Paragph style={{
          textIndent:0
        }}>
          <Question>
          Jakie są wskazania do wykonania protez akrylowych?
          </Question>
          <Response>
          Wskazaniem do wykonania konwencjonalnych protez akrylowych jest całkowite bezzębie u pacjenta – wtedy
wykonuje się protezy całkowite lub liczne braki zębowe przy braku możliwości innego leczenia protetycznego.
          </Response>


          <Question>
          Jak często należy zmieniać protezy na nowe?
          </Question>
          <Response>
          Podczas użytkowania protez należy zgłaszać się na wizyty kontrolne raz w roku, lekarz stomatolog ocenia wtedy
stan protezy i jej dopasowanie w jamie ustnej. Zwykle po 5-7 latach wskutek zaniku podłoża protetycznego w
jamie ustnej proteza przestaje idealnie pasować, pogarsza się jej utrzymanie, przestaje być stabilna i zaczyna
spadać – jest to wskazanie do wymiany protezy.
          </Response>


          <Question>
          Jak długi jest czas oczekiwania na wykonanie protezy akrylowej?
          </Question>
          <Response>
          Od pierwszej wizyty w gabinecie do oddania gotowych protez akrylowych około 1-2 tygodnie.
          </Response>

          <Question>
          Jakie są wady protez natychmiastowych?
          </Question>
          <Response>
          Do wad protez natychmiastowych należy zaliczyć konieczność ich podścielenia i korekty po okresie wygojenia
rany po usunięciu zęba, przy mnogich ekstrakcjach protezy te traktowane są jako tymczasowe i po zagojeniu
dziąsła wskazana jest ich wymiana.
          </Response>


          <Question>
          Czy trudno się przyzwyczaić do protezy natychmiastowej?
          </Question>
          <Response>
          Adaptacja do takiej protezy jest znacznie łatwiejsza niż do protezy wykonywanej dopiero po wygojeniu rany
poekstrakcyjnej. Jest to spowodowane tym, że nie nie ma okresu, w którym pacjent może przyzwyczaić się do
braku usuniętych zębów.
          </Response>


          <Question>
          Czy protezy szkieletowe są widoczne w jamie ustnej?
          </Question>
          <Response>
          Nie są widoczne, ponieważ metalowy szkielet umieszczony jest od wewnętrznej strony zębów, niestety czasami,
aby zapewnić najlepsze utrzymanie, metalowe klamry mocujące muszą być poprowadzone w strefie estetycznej i
wtedy mogą być one widoczne – alternatywą są wtedy protezy szkieletowe acetalowe lub protezy szkieletowe
bezklamrowe.
          </Response>


          <Question>
          Czy są przeciwwskazania do stosowania protez szkieletowych?
          </Question>
          <Response>
          Nie ma żadnych przeciwwskazań, jest to najlepsze rozwiązanie protetyczne wśród ruchomych protez. Z powodu
podparcia i utrzymywania protezy szkieletowej na klamrach w jamie ustnej pacjenta muszą być własne zęby,
które posłużą jako filary.
          </Response>


          <Question>
            Czy protezy szkieletowe są trwalsze niż protezy akrylowe?
          </Question>
          <Response>
          Tak, jest to możliwe, gdyż wykonane są z metalu, który zapewnia dużo większą trwałość przy dużo mniejszych
wymiarach.
          </Response>


          <Question>
            Jakie są wskazania do wykonania koron?
          </Question>
          <Response>
          Wskazaniem jest duże zniszczenie tkanek zęba oraz niezadowalający wygląd estetyczny zęba.
          </Response>


          <Question>
          Jaka jest różnica pomiędzy koroną licowaną a pełnoceramiczną?
          </Question>
          <Response>
          Różnica polega na materiale użytym do wykonania korony protetycznej: korony licowane porcelaną składają się z
metalowego szkieletu, na którym napalana jest porcelana, natomiast korony pełnoceramiczne wykonane są w
całości z porcelany, co sprawia, że charakteryzują się one najlepszym możliwym efektem estetycznym.
          </Response>

          <Question>
          Jakie są wskazania do wykonania mostów protetycznych?
          </Question>
          <Response>
          Wskazaniem są każde braki zębowe pod warunkiem obecności odpowiednio ulokowanych zębów filarowych – o
tym można się przekonać dopiero po badaniu stomatologicznym.
          </Response>


          <Question>
          Jak długi jest czas wykonania uzupełnienia za pomocą mostów protetycznych?
          </Question>
          <Response>
          Od pierwszej wizyty do zakończenia leczenia mija ok. 5-8 dni, po tym czasie można na nowo cieszyć się pięknym
uśmiechem i odtworzoną czynnością żucia.
          </Response>


          <Question>
          Jakie są inne możliwości odbudowy braku pojedynczych zębów?
          </Question>
          <Response>
          Alternatywą dla stosowania klasycznych mostów protetycznych jest odbudowa braku zęba za pomocą
pojedynczych koron na implantach, rozwiązanie takie jest korzystniejsze z powodu braku konieczności
szlifowania sąsiednich zdrowych zębów pacjenta.
          </Response>
        </Paragph>

      </RightContainer>
    </MainContainer>
  </Layout>);
};

export default Protetyka;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
